import React from 'react';
import Chart from 'react-apexcharts';

type ChartsProps = {
  color: string;
  series: number[];
};

const SparklineChart: React.FC<ChartsProps> = ({ color, series }) => {
  const options: ApexCharts.ApexOptions = {
    chart: {
      group: 'sparks',
      type: 'line',
      height: 80,
      sparkline: {
        enabled: true,
      },
    },
    series: [
      {
        data: series,
      },
    ],
    stroke: {
      curve: 'smooth',
      width: 1.5,
    },
    markers: {
      size: 0,
    },
    grid: {
      padding: {
        top: 10,
        bottom: 10,
        left: 10,
      },
    },
    colors: [color],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    tooltip: {
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function () {
            return '';
          },
        },
        formatter: function (value) {
          return Math.round(value).toString();
        },
      },
      cssClass: '!text-black',
    },
  };

  return <Chart options={options} series={options.series} type="line" width={100} />;
};

export default SparklineChart;

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Utils
import {
  getClassNames,
  getRiskColor,
  getRiskLevelColor,
  getRiskTitle,
} from '@utils/helpers';

// Redux
import { setRiskLevelFilter } from '@redux/slice/projectSlice';
import { IRootState } from '@redux/store';

// Components
import { Button } from '@/components/button';
import { Option } from '@/components/FormElement/types';
import { PAYEE_TRUST_INDEX } from '@/utils/constants';
import ToolTip from '@components/ToolTip';
import moment from 'moment';
import SparklineChart from './charts/charts';
import { Shopper } from './shopperDetails';

type ShoppersChartsProps = {
  iref: React.RefObject<HTMLDivElement>;
  selectedMonth: Option;
};

interface DateValueObject {
  date: string[];
  value: number;
}

const ShoppersCharts: React.FC<ShoppersChartsProps> = ({ iref, selectedMonth }) => {
  const dispatch = useDispatch();
  const { shoppers, riskLevelFilter } = useSelector((state: IRootState) => state.project);

  function monthSeriesData(): DateValueObject[] {
    const currentDate = moment(selectedMonth.value);
    const year = currentDate.format('YYYY');
    const month = currentDate.format('MM');
    let daysInMonth = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth();

    const isCurrentMonth = `${year}-${month}` === moment().format('YYYY-MM');
    if (isCurrentMonth) {
      daysInMonth = moment().diff(moment().startOf('month'), 'days') + 1;
    }

    const result: DateValueObject[] = [];
    let datesGroup: string[] = [];

    for (let day = 1; day <= daysInMonth; day++) {
      const formattedDate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
      datesGroup.push(formattedDate);
      if (datesGroup.length === 3 || day === daysInMonth) {
        result.push({ date: datesGroup, value: 0 });
        datesGroup = [];
      }
    }

    return result;
  }

  function seriesValues(shopper: Shopper[], risk_level: string): number[] {
    const resultArray = monthSeriesData();
    shopper = [...shopper]
      ?.filter(
        (shop) =>
          shop.created_at.substring(0, 7) == selectedMonth.value &&
          shop.risk_level === risk_level
      )
      .sort(
        (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      );
    shopper.forEach((dataObj) => {
      const createdAtDate = dataObj.created_at.substring(0, 10);
      resultArray.forEach((dateValueObj) => {
        if (dateValueObj.date.includes(createdAtDate)) {
          dateValueObj.value += 1;
        }
      });
    });

    return resultArray.map((obj) => obj.value);
  }

  function calculatePercentage(shopper: Shopper[], risk_level: string) {
    let currentMonthTotal = 0;
    let previousMonthTotal = 0;
    shopper
      .filter((shopData) => shopData.risk_level === risk_level)
      .map((shop) => {
        const previousMonth = moment(selectedMonth.value + '-01')
          .subtract(1, 'months')
          .format('YYYY-MM');
        if (moment(shop.created_at).format('YYYY-MM') == selectedMonth.value)
          currentMonthTotal++;
        if (moment(shop.created_at).format('YYYY-MM') == previousMonth) {
          previousMonthTotal++;
        }
      });
    let avg = 0;
    if (previousMonthTotal > 0 && currentMonthTotal > 0) {
      avg = ((currentMonthTotal - previousMonthTotal) / previousMonthTotal) * 100;
    } else if (currentMonthTotal > 0) {
      avg = 100;
    }
    return isNaN(avg) ? 0 : avg;
  }

  const scorePercentage = (score: number) => {
    return (
      <span
        className={`text-${score >= 0 ? 'score-green' : 'red-alert'} font-medium text-sm leading-sm`}
      >
        {score > 0 ? '+' : ''}
        {Math.round(score)}%
      </span>
    );
  };
  const { selectedProject } = useSelector((state: IRootState) => state.project);
  const data = useMemo(
    () => [
      {
        risk: 'trusted',
        risk_level: { label: 'Trusted Consumers', value: 'LOW_RISK_CONSUMER' },
        score: 80,
        title:
          selectedProject?.products === String(PAYEE_TRUST_INDEX)
            ? 'Trusted Payees'
            : 'Trusted Consumers',
        value:
          selectedProject?.products === String(PAYEE_TRUST_INDEX)
            ? 15050
            : shoppers?.filter((users) => users.risk_level === 'LOW_RISK_CONSUMER')
                .length || 0,
        date: selectedMonth?.label,
        info: '0% of 0 consumers',
        chart: (
          <SparklineChart
            color={'#0ccfa0'}
            series={
              selectedProject?.products === String(PAYEE_TRUST_INDEX)
                ? [0, 8]
                : shoppers
                  ? seriesValues(shoppers, 'LOW_RISK_CONSUMER')
                  : []
            }
          />
        ),
        percentage:
          selectedProject?.products === String(PAYEE_TRUST_INDEX)
            ? 8
            : shoppers
              ? calculatePercentage(shoppers, 'LOW_RISK_CONSUMER')
              : 0,
      },
      {
        risk: 'medium',
        risk_level: {
          label: 'Medium Risk Consumers',
          value: 'MEDIUM_RISK_CONSUMER',
        },
        score: 79,
        title:
          selectedProject?.products === String(PAYEE_TRUST_INDEX)
            ? 'Medium Risk Payees'
            : 'Medium Risk Consumers',
        value:
          selectedProject?.products === String(PAYEE_TRUST_INDEX)
            ? 12902
            : shoppers?.filter((users) => users.risk_level === 'MEDIUM_RISK_CONSUMER')
                .length || 0,
        date: selectedMonth?.label,
        info: '0% of 0 consumers',
        chart: (
          <SparklineChart
            color="#efb604"
            series={
              selectedProject?.products === String(PAYEE_TRUST_INDEX)
                ? [0, 8]
                : shoppers
                  ? seriesValues(shoppers, 'MEDIUM_RISK_CONSUMER')
                  : []
            }
          />
        ),
        percentage:
          selectedProject?.products === String(PAYEE_TRUST_INDEX)
            ? 8
            : shoppers
              ? calculatePercentage(shoppers, 'MEDIUM_RISK_CONSUMER')
              : 0,
      },
      {
        risk: 'high',
        risk_level: { label: 'High Risk Consumers', value: 'HIGH_RISK_CONSUMER' },
        score: 69,
        title:
          selectedProject?.products === String(PAYEE_TRUST_INDEX)
            ? 'High Risk Payees'
            : 'High Risk Consumers',
        value:
          selectedProject?.products === String(PAYEE_TRUST_INDEX)
            ? 426
            : shoppers?.filter((users) => users.risk_level === 'HIGH_RISK_CONSUMER')
                .length || 0,
        date: selectedMonth?.label,
        info: '0% of 0 consumers',
        chart: (
          <SparklineChart
            color="#e32d2d"
            series={
              selectedProject?.products === String(PAYEE_TRUST_INDEX)
                ? [0, 0]
                : shoppers
                  ? seriesValues(shoppers, 'HIGH_RISK_CONSUMER')
                  : []
            }
          />
        ),
        percentage:
          selectedProject?.products === String(PAYEE_TRUST_INDEX)
            ? 4
            : shoppers
              ? calculatePercentage(shoppers, 'HIGH_RISK_CONSUMER')
              : 0,
      },
    ],
    [selectedMonth, shoppers]
  );
  const [scoreList, setScoreList] = useState(data);
  useEffect(() => {
    if (shoppers) {
      // const filteredShoppers = shoppers?.filter(
      //   (user) => user.created_at.substring(0, 7) == selectedMonth.value
      // );
      data?.map((result) => {
        if (selectedProject?.products !== String(PAYEE_TRUST_INDEX)) {
          result.value = shoppers?.filter(
            (users) =>
              (result.risk === 'trusted' && users.risk_level === 'LOW_RISK_CONSUMER') ||
              (result.risk === 'medium' && users.risk_level === 'MEDIUM_RISK_CONSUMER') ||
              (result.risk === 'high' && users.risk_level === 'HIGH_RISK_CONSUMER')
          ).length;
        }
        result.info = `${Math.round(
          (result?.value && shoppers?.length ? result.value / shoppers?.length : 0) * 100
        )}%  of ${shoppers?.length} consumers`;
        return undefined;
      });
      setScoreList(data);
    }
  }, [shoppers]);

  const handleRiskLevelFilter = (risk_level: { label: string; value: string }) => {
    if (risk_level.value === riskLevelFilter.value)
      dispatch(setRiskLevelFilter({ label: 'Recent', value: '' }));
    else dispatch(setRiskLevelFilter(risk_level));
  };
  return (
    <div className="grid grid-cols-3 gap-x-2 mt-2" ref={iref}>
      {scoreList.map((item) => (
        <div
          className={getClassNames(
            'group relative p-4 bg-white hover:bg-grey-stroke-tertiary border border-grey-stroke-primary',
            'flex justify-between items-end rounded-lg'
          )}
          key={item.risk}
        >
          <div className="flex flex-col group relative">
            <RiskTag risk={item.risk} title={item.title} />
            <span className="header4 mb-1">{item.value}</span>
            {scorePercentage(item.percentage)}
            <ToolTip
              info={item.info}
              className="top-14 min-w-[132px] whitespace-nowrap text-sm"
            />
          </div>
          {item.chart}
          <Button
            title="View"
            color="light"
            onClick={() => handleRiskLevelFilter(item.risk_level)}
            className={getClassNames(
              'hidden group-hover:block absolute z-50 top-2 right-2',
              'py-1 md:py-1 lg:py-1 px-2 md:px-2 lg:px-2 text-sm1'
            )}
          />
        </div>
      ))}
    </div>
  );
};

export default ShoppersCharts;

type ScoreBubbleProps = {
  score: number;
  inactive?: boolean;
  risk_level: string;
  isPayeeProduct?: boolean;
};

export const ScoreBubble: React.FC<ScoreBubbleProps> = ({
  score,
  inactive,
  risk_level,
  isPayeeProduct,
}) => {
  const { selectedProject } = useSelector((state: IRootState) => state.project);
  return (
    <div
      className={getClassNames(
        'py-4 px-6 bg-grey text-[3.5rem] flex items-center justify-center w-[110px] h-[110px] rounded-full',
        getRiskLevelColor(
          risk_level,
          inactive,
          isPayeeProduct !== undefined
            ? isPayeeProduct ?? false
            : selectedProject?.products === String(PAYEE_TRUST_INDEX)
        )
      )}
    >
      {score}
    </div>
  );
};

type RiskTagProps = {
  title?: string;
  risk: string;
  className?: string;
  inactive?: boolean;
  isConsentActive?: boolean;
};

export const RiskTag: React.FC<RiskTagProps> = ({
  title,
  risk,
  className = '',
  inactive = false,
  isConsentActive = true,
}) => {
  const { selectedProject } = useSelector((state: IRootState) => state.project);
  return (
    <div className={getClassNames('flex items-center gap-x-1', className)}>
      <div
        className={getClassNames(
          'w-3 h-3 rounded-sm',
          getRiskColor(
            risk,
            inactive,
            selectedProject?.products === String(PAYEE_TRUST_INDEX),
            isConsentActive
          )
        )}
      />
      <span className="text-grey-content-secondary">
        {title ??
          getRiskTitle(risk, selectedProject?.products === String(PAYEE_TRUST_INDEX))}
      </span>
    </div>
  );
};

import { useAxiosGet, useAxiosPost, useAxiosPut } from '@/hooks/useAxios';

export const findUserApi = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const findUser = async (query?: string) => {
    const response = await callApi(`/organisation_user/finduser${query ?? ''}`);
    return response.data;
  };
  return { findUser, isError, isLoading, isSuccess };
};

export const sendUserInvitationApi = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();

  const sendUserInvitation = async (data: object) => {
    const response = await callApi(`/organisation/send-invitation`, data);
    return response.data;
  };
  return { sendUserInvitation, isError, isLoading, isSuccess };
};

export const deactiveUserPromptApi = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();

  const deactiveUserPrompt = async (data: object) => {
    const response = await callApi(`/organisation_user/deactive-prompt`, data);
    return response.data;
  };
  return { deactiveUserPrompt, isError, isLoading, isSuccess };
};

import { ReactComponent as CaretIcon } from '@icons/arrow_down.svg';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

// Helpers
import { getClassNames, renderNameIcon } from '@utils/helpers';

// Components
import { DemoGuidePopoverContext } from '@/context/demoGuidePopover';
import { getNavigationOptions } from '@/hooks/useRolePermission';
import { getActiveOrganization, getUserOrganization } from '@/redux/slice/authSlice';
import { browserSelector } from '@/redux/slice/browserType.slice';
import { IRootState } from '@/redux/store';
import { ORGANIZATION_TYPES, PRIVATE_NAVIGATION } from '@/utils/constants';
import DemoGuidePopover from '../DemoGuidePopover';
import SwitchOrganization, { organizationType } from '../GlobalModals/SwitchOrganization';
import { Button } from '../button';

const SideBar = ({ activeNav }: { activeNav?: number }) => {
  const [orgViewModal, setOrgViewModal] = useState(false);
  const navigate = useNavigate();
  const { navOptions1, navOptions2 } = getNavigationOptions();
  const activeOrganizationData = useSelector(getActiveOrganization);
  const userOrganizationData = useSelector(getUserOrganization);
  const browserType = useSelector(browserSelector);
  const { activeProject } = useSelector((state: IRootState) => state.project);
  const { popUp1, setPopUp1, setPopUp2, setPopUp4, setPopUp5, setPopUp6, popUp6 } =
    React.useContext(DemoGuidePopoverContext);
  const [partnerOrganization, setPartnerOrganization] = useState<organizationType | null>(
    null
  );

  return (
    <div
      className={getClassNames(
        'min-h-screen bg-primary flex flex-col transition-all border border-stroke',
        'min-w-[245px]'
      )}
    >
      {orgViewModal && (
        <SwitchOrganization
          isOpen={orgViewModal}
          closeModal={setOrgViewModal}
          partnerOrganization={partnerOrganization}
          setPartnerOrganization={setPartnerOrganization}
        />
      )}
      <div className="pl-6 h-[62px] flex items-center">
        <div className="body-black  ">
          <div className="flex items-center gap-2.5">
            {renderNameIcon({
              name: activeOrganizationData?.name ?? '',
              className:
                'w-[22px] h-[22px] bg-blacked text-lg font-bold leading-5 text-white',
              textClassName:
                ' flex items-center ml-2 text-body-black text-lg font-bold leading-5',
              isLimitLongText: true,
              imageUrl: activeOrganizationData?.image ?? '',
            })}
          </div>
        </div>
      </div>
      <hr className="border-theme-stroke" />
      <div className="flex-1 bg-primary">
        <div
          className="bg-primary mt-5 pl-6 pr-4 flex flex-col gap-y-0.5 pb-4"
          id="target1"
        >
          <DemoGuidePopover
            title="Home"
            body="The operational dashboard supporting  operations teams with consumer fraud data, analytics and tools to prevent fraud."
            el={() => document.getElementById('target1')}
            topOffset={40}
            leftOffset={25}
            arrowPosition="right"
            maxWidth="max-w-[355px]"
            open={popUp1}
            setOpen={setPopUp1}
            setNext={setPopUp2}
          />
          <DemoGuidePopover
            title="Projects"
            el={() => document.getElementById('target1')}
            topOffset={-40}
            leftOffset={230}
            arrowPosition="left"
            open={popUp6}
            setOpen={setPopUp6}
            setPrev={
              activeProject?.products === 'CHECKOUT_AND_REFUND_MANAGER'
                ? setPopUp5
                : setPopUp4
            }
          >
            <div>
              <p className="text-grey-content-tertiary mt-3">
                Let’s set up a project to configure the fraud risk algorithm and
                personalised payments experiences.
              </p>
              <button
                className="mt-2 text-white2 text-sm1 py-1 underline"
                onClick={() => {
                  navigate(PRIVATE_NAVIGATION.projects.manage.path);
                  setPopUp6(false);
                }}
              >
                Go to Projects
              </button>
            </div>
          </DemoGuidePopover>
          {navOptions1.map((item) => (
            <Link to={item.to} key={item.id}>
              <span
                className={getClassNames(
                  activeNav === item.id
                    ? 'text-primary bg-active-nav/5'
                    : 'text-secondary',
                  item.to
                    ? 'hover:text-primary hover:bg-active-nav/5'
                    : 'cursor-default opacity-50',
                  'flex items-center justify-between w-full py-2 px-2 rounded-[5px]',
                  'font-medium text-base1'
                )}
              >
                <span>{item.name}</span>
              </span>
            </Link>
          ))}
        </div>

        <hr className="border-theme-stroke" />
        <div className="pt-5 pl-6 pr-4 flex flex-col gap-y-0.5 pb-4">
          <div
            className={getClassNames(
              'text-grey-content-tertiary',
              'cursor-default opacity-50 flex items-center justify-between w-full',
              'py-2 px-2 rounded-[5px] font-medium text-base1'
            )}
          >
            <span>Help & Resources</span>
          </div>
          {navOptions2.map((item) => (
            <Link
              to={item.to}
              key={item.id}
              target={
                item.isExternal && browserType?.isChromeBrowser ? '_blank' : '_self'
              }
            >
              <span
                className={getClassNames(
                  activeNav === item.id
                    ? 'text-primary bg-active-nav/5'
                    : 'text-secondary',
                  item.to
                    ? 'hover:text-primary hover:bg-active-nav/5'
                    : 'cursor-default opacity-50',
                  'flex items-center justify-between w-full py-2 px-2 rounded-[5px]',
                  'font-medium text-base1'
                )}
              >
                <span>{item.name}</span>
              </span>
            </Link>
          ))}
        </div>
      </div>
      {userOrganizationData?.organization_type !== ORGANIZATION_TYPES.MERCHANT && (
        <div className="h-10 px-8">
          <Button
            title="Switch organisation"
            className="border-0 w-full flex justify-between text-body-black text-base"
            suffixIcon={<CaretIcon />}
            onClick={async () => {
              setOrgViewModal(true);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default SideBar;

import { createContext, useEffect, useState } from 'react';
// import ControlButtons from "@/components/ControlButton";
// import EndDemoPopup from "@/components/endDemo";

interface AuthContextProps {
  readonly popUp1: boolean;
  readonly setPopUp1: React.Dispatch<React.SetStateAction<boolean>>;
  readonly popUp2: boolean;
  readonly setPopUp2: React.Dispatch<React.SetStateAction<boolean>>;
  readonly popUp3: boolean;
  readonly setPopUp3: React.Dispatch<React.SetStateAction<boolean>>;
  readonly popUp4: boolean;
  readonly setPopUp4: React.Dispatch<React.SetStateAction<boolean>>;
  readonly popUp5: boolean;
  readonly setPopUp5: React.Dispatch<React.SetStateAction<boolean>>;
  readonly popUp6: boolean;
  readonly setPopUp6: React.Dispatch<React.SetStateAction<boolean>>;
  readonly popUp7: boolean;
  readonly setPopUp7: React.Dispatch<React.SetStateAction<boolean>>;
  readonly popUp8: boolean;
  readonly setPopUp8: React.Dispatch<React.SetStateAction<boolean>>;
  readonly popUp9: boolean;
  readonly setPopUp9: React.Dispatch<React.SetStateAction<boolean>>;
  readonly popUp10: boolean;
  readonly setPopUp10: React.Dispatch<React.SetStateAction<boolean>>;
  readonly popUp11: boolean;
  readonly setPopUp11: React.Dispatch<React.SetStateAction<boolean>>;
  readonly popUp12: boolean;
  readonly setPopUp12: React.Dispatch<React.SetStateAction<boolean>>;
  readonly popUp13: boolean;
  readonly setPopUp13: React.Dispatch<React.SetStateAction<boolean>>;
  readonly popUp14: boolean;
  readonly setPopUp14: React.Dispatch<React.SetStateAction<boolean>>;
  readonly popUp15: boolean;
  readonly setPopUp15: React.Dispatch<React.SetStateAction<boolean>>;
  readonly popUp16: boolean;
  readonly setPopUp16: React.Dispatch<React.SetStateAction<boolean>>;
  readonly popUp17: boolean;
  readonly setPopUp17: React.Dispatch<React.SetStateAction<boolean>>;
  readonly popUp18: boolean;
  readonly setPopUp18: React.Dispatch<React.SetStateAction<boolean>>;
  readonly popUp19: boolean;
  readonly setPopUp19: React.Dispatch<React.SetStateAction<boolean>>;
  readonly popUp20: boolean;
  readonly setPopUp20: React.Dispatch<React.SetStateAction<boolean>>;
  readonly popUp21: boolean;
  readonly setPopUp21: React.Dispatch<React.SetStateAction<boolean>>;
  readonly popUp22: boolean;
  readonly setPopUp22: React.Dispatch<React.SetStateAction<boolean>>;
  readonly popUp23: boolean;
  readonly setPopUp23: React.Dispatch<React.SetStateAction<boolean>>;
  readonly exploreDemo: boolean;
  readonly setExploreDemo: React.Dispatch<React.SetStateAction<boolean>>;
  readonly showExploreDemo: boolean;
  readonly setShowExploreDemo: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DemoGuidePopoverContext = createContext<AuthContextProps>({
  popUp1: false,
  setPopUp1: () => {},
  popUp2: false,
  setPopUp2: () => {},
  popUp3: false,
  setPopUp3: () => {},
  popUp4: false,
  setPopUp4: () => {},
  popUp5: false,
  setPopUp5: () => {},
  popUp6: false,
  setPopUp6: () => {},
  popUp7: false,
  setPopUp7: () => {},
  popUp8: false,
  setPopUp8: () => {},
  popUp9: false,
  setPopUp9: () => {},
  popUp10: false,
  setPopUp10: () => {},
  popUp11: false,
  setPopUp11: () => {},
  popUp12: false,
  setPopUp12: () => {},
  popUp13: false,
  setPopUp13: () => {},
  popUp14: false,
  setPopUp14: () => {},
  popUp15: false,
  setPopUp15: () => {},
  popUp16: false,
  setPopUp16: () => {},
  popUp17: false,
  setPopUp17: () => {},
  popUp18: false,
  setPopUp18: () => {},
  popUp19: false,
  setPopUp19: () => {},
  popUp20: false,
  setPopUp20: () => {},
  popUp21: false,
  setPopUp21: () => {},
  popUp22: false,
  setPopUp22: () => {},
  popUp23: false,
  setPopUp23: () => {},
  exploreDemo: false,
  setExploreDemo: () => {},
  showExploreDemo: false,
  setShowExploreDemo: () => {},
});

const DemoGuidePopoverContextProvider: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const [popUp1, setPopUp1] = useState<boolean>(true);
  const [popUp2, setPopUp2] = useState<boolean>(false);
  const [popUp3, setPopUp3] = useState<boolean>(false);
  const [popUp4, setPopUp4] = useState<boolean>(false);
  const [popUp5, setPopUp5] = useState<boolean>(false);
  const [popUp6, setPopUp6] = useState<boolean>(false);
  const [popUp7, setPopUp7] = useState<boolean>(true);
  const [popUp8, setPopUp8] = useState<boolean>(false);
  const [popUp9, setPopUp9] = useState<boolean>(true);
  const [popUp10, setPopUp10] = useState<boolean>(true);
  const [popUp11, setPopUp11] = useState<boolean>(false);
  const [popUp12, setPopUp12] = useState<boolean>(false);
  const [popUp13, setPopUp13] = useState<boolean>(false);
  const [popUp14, setPopUp14] = useState<boolean>(false);
  const [popUp15, setPopUp15] = useState<boolean>(false);
  const [popUp16, setPopUp16] = useState<boolean>(false);
  const [popUp17, setPopUp17] = useState<boolean>(false);
  const [popUp18, setPopUp18] = useState<boolean>(false);
  const [popUp19, setPopUp19] = useState<boolean>(false);
  const [popUp20, setPopUp20] = useState<boolean>(false);
  const [popUp21, setPopUp21] = useState<boolean>(false);
  const [popUp22, setPopUp22] = useState<boolean>(false);
  const [popUp23, setPopUp23] = useState<boolean>(false);
  const [exploreDemo, setExploreDemo] = useState<boolean>(false);
  const [showExploreDemo, setShowExploreDemo] = useState<boolean>(false);

  useEffect(() => {
    if (exploreDemo) {
      setTimeout(() => {
        setShowExploreDemo(false);
        setExploreDemo(false);
      }, 5000);
    }
  }, [exploreDemo, showExploreDemo]);

  return (
    <DemoGuidePopoverContext.Provider
      value={{
        popUp1,
        setPopUp1,
        popUp2,
        setPopUp2,
        popUp3,
        setPopUp3,
        popUp4,
        setPopUp4,
        popUp5,
        setPopUp5,
        popUp6,
        setPopUp6,
        popUp7,
        setPopUp7,
        popUp8,
        setPopUp8,
        popUp9,
        setPopUp9,
        popUp10,
        setPopUp10,
        popUp11,
        setPopUp11,
        popUp12,
        setPopUp12,
        popUp13,
        setPopUp13,
        popUp14,
        setPopUp14,
        popUp15,
        setPopUp15,
        popUp16,
        setPopUp16,
        popUp17,
        setPopUp17,
        popUp18,
        setPopUp18,
        popUp19,
        setPopUp19,
        popUp20,
        setPopUp20,
        popUp21,
        setPopUp21,
        popUp22,
        setPopUp22,
        popUp23,
        setPopUp23,
        exploreDemo,
        setExploreDemo,
        showExploreDemo,
        setShowExploreDemo,
      }}
    >
      {children}
      {/* <ControlButtons /> */}
      {/* {showExploreDemo && <EndDemoPopup />} */}
    </DemoGuidePopoverContext.Provider>
  );
};

export default DemoGuidePopoverContextProvider;
